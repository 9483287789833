<template>
  <div>
    <div class="card-body pt-0 pb-3 pt-7 card card-custom card-stretch gutter-b">
      <div class="col-xxl-12 order-1 order-xxl-2">
        <div class="table-responsive">
          <table id="my-table" class="table table-head-custom table-vertical-center table-head-bg table-borderless">
            <thead>
              <tr>
                <th class="pl-0 pt-8"></th>
                <th class="table-mw-150 text-center" @click="sort('name')">
                  User
                  <i class="fas fa-sort"></i>
                </th>
                <th class="table-mw-100 text-center">Car Brand</th>
                <th class="table-mw-100 text-center">Year of Manufacture</th>
                <th class="table-mw-100 text-center">Licence Plate</th>
                <th class="table-mw-100"></th>
              </tr>
            </thead>
            <tbody>
              <sc-user-overview-table
                @update="onStep1Update"
                v-for="(item, i) in list"
                :key="i"
                :id="item._id"
                :name="item.firstname + ' ' + item.lastname"
                :carBrand="item.vehicles"
                :image="`${url}/uploads/default/${item.photoUrl}`"
                :licensePlate="item.vehicles"
                :yearOfManufacture="item.vehicles"
              />
            </tbody>
          </table>
          <b-pagination
            v-model="currentPage"
            :list="list"
            :per-page="perPage"
            aria-controls="my-table"
            align="center"
            class="mt-10"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ScUserOverviewTable from '@/view/content/widgets/advance-table/sc-user-overview-table.vue'
import ApiService from '@/core/services/api.service'
import { base_url } from '../../../const'
import UsersServices from '../../../core/services/users'

export default {
  name: 'user',
  data() {
    return {
      currentPage: 1,
      perPage: 8,
      list: [],
      url: base_url,
      currentSort: true,
    }
  },

  components: {
    ScUserOverviewTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'User' }])
    this.users()
  },
  methods: {
    users() {
      ApiService.setHeader()
      ApiService.get('users').then(({ data }) => {
        this.list = data
      })
    },
    detail() {},
    blacklist() {},
    async onStep1Update(userId) {
      const deleteUser = await UsersServices.delete(userId)
      console.log(`deleteUser`, deleteUser)
      console.log(`userId`, userId)

      const newList = this.list.filter(list => list._id != userId)
      this.list = newList
    },
    sort: function () {
      this.currentSort = !this.currentSort
      console.log(`this.currentSort`, this.currentSort)
      let modifier = 1
      if (this.currentSort === true) modifier = -1
      this.list.sort(function (a, b) {
        if (a.firstname === null || b.firstname === null) {
          return -1 * modifier
        }

        const nameA = a.firstname.toUpperCase() // ignore upper and lowercase
        const nameB = b.firstname.toUpperCase() // ignore upper and lowercase

        if (nameA < nameB) {
          return -1 * modifier
        }
        if (nameA > nameB) {
          return 1 * modifier
        }

        // names must be equal
        return 0
      })
    },
  },
}
</script>

<style>
.table-mw-150 {
  min-width: 150px;
}
.table-mw-100 {
  min-width: 100px;
}
</style>
