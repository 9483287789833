<template v-if="brand">
  <!--begin::Advance Table Widget 9-->

  <!--begin::Table-->

  <tr class="" style="border-bottom: 1px solid #e8eaf1">
    <td class="pl-0 pt-8">
      <div class="d-flex align-items-center justify-content-center">
        <div class="symbol symbol-50 symbol-light">
          <i class="fas fa-crown text-primary"></i>
        </div>
      </div>
    </td>
    <td class="pl-0 pt-8 table-mw-150" style="min-width: 150px">
      <div class="d-flex align-items-center">
        <div class="symbol symbol-50 symbol-light mr-4" id="app">
          <span class="symbol-label">
            <img :src="image" class="h-100 align-self-end" alt="" />
          </span>
        </div>
        <div>
          <span class="text-dark-75 font-weight-bolder mb-1 font-size-sm">{{ name }}</span>
        </div>
      </div>
    </td>
    <td class="table-mw-100">
      <div class="container">
        <ul class="list-group list-group-flush">
          <li v-for="item in carBrand" :key="item.carBrand" class="list-group-item">
            <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ item.brand.value }}</span>
          </li>
        </ul>
      </div>
      <!-- <ul id="example-1">
        <li v-for="item in carBrand" :key="item.carBrand">
          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ item.brand.value }}</span>
        </li>
      </ul> -->

      <!-- <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ carBrand }}</span> -->
    </td>
    <td class="table-mw-100">
      <div class="container">
        <ul class="list-group list-group-flush">
          <li v-for="item in yearOfManufacture" :key="item.yearOfManufacture" class="list-group-item">
            <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
              item.productionPeriod.value
            }}</span>
          </li>
        </ul>
      </div>
      <!-- <ul id="example-1">
        <li v-for="item in yearOfManufacture" :key="item.yearOfManufacture">
          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{
            item.productionPeriod.value
          }}</span>
        </li>
      </ul> -->
      <!-- <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ yearOfManufacture }}</span> -->
    </td>
    <td class="table-mw-100">
      <div class="container">
        <ul class="list-group list-group-flush">
          <li v-for="item in licensePlate" :key="item.licensePlate" class="list-group-item">
            <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ item.licencePlate }}</span>
          </li>
        </ul>
      </div>
      <!-- <ul id="example-1">
        <li v-for="item in licensePlate" :key="item.licensePlate">
          <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ item.licencePlate }}</span>
        </li>
      </ul> -->
      <!-- <span class="text-dark-75 font-weight-bolder d-block font-size-sm mt-6">{{ licensePlate }}</span> -->
    </td>
    <td class="pr-0 text-right table-mw-100">
      <router-link class="btn btn-light-success w-100" :to="`/user/detail/${id}`">
        <i class="fas fa-eye fa-1x mr-1"></i>Detail</router-link
      >
      <b-button class="btn btn-light-danger w-100 mt-5" v-on:click="deleteAction(id)">
        <i class="fas fa-trash fa-1x mr-1"></i>Delete</b-button
      >
    </td>
  </tr>
</template>

<script>
import Swal from 'sweetalert2'
export default {
  name: 'sc-user-overview-table',
  props: {
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
    },
    image: {
      type: String,
      defaul: '',
    },
    carBrand: {
      type: Array,
    },
    licensePlate: {
      type: Array,
    },
    yearOfManufacture: {
      type: Array,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 8,
    }
  },
  methods: {
    deleteAction: async function (userId) {
      // FIXME : text değişebilir
      Swal.fire({
        title: 'Sure?',
        text: 'Are you sure you really want to delete this user?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#181C32',
        cancelButtonColor: '#c3c3c3',
        confirmButtonText: 'Yes, delete',
      }).then(result => {
        console.log(`result`, result)
        if (result.isConfirmed) {
          this.$emit('update', userId)
          Swal.fire('Deleted!', '', 'success')
        }
      })
    },
  },
}
</script>
